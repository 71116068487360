* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.wrapper {
  background-image: url(https://images.unsplash.com/photo-1612375689547-b5351e63050b?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_box {
  position: relative;
  width: 450px;
  height: 500px;
  backdrop-filter: blur(40px);
  border: 2px solid white;
  border-radius: 16px;
  transition: transform 0.6s ease, height 0.2s ease-in-out,
    opacity 0.5s ease-in-out;
  overflow: hidden;
}

.form_box form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.5s ease;
}

.input_box {
  width: 80%;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 2px solid white;
  align-items: center;
  margin: 40px 0px;
}

.input_box div {
  display: flex;
  align-items: center;
}

.input_box div label {
  transition: 0.4s;
  padding-left: 10px;
  font-size: 17px;
}

.input_box input {
  width: 100%;
  position: absolute;
  font-size: 18px;
  border: none;
  background: none;
  padding-left: 30px;
  height: 100%;
  outline: none;
}

.input_box input:focus {
  border-bottom: 1px solid lightblue;
}

.input_box div input:focus ~ label,
.input_box div input:valid ~ label {
  transform: translateY(-25px);
}

.input_box ion-icon {
  font-size: 25px;
}

.check_box {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.submit_btn {
  width: 80%;
  height: 35px;
  cursor: pointer;
  border-radius: 20px;
  margin: 20px 0px;
  border: none;
  font-size: 18px;
}

.form_box h5,
.check_box label,
.check_box input {
  cursor: pointer;
}
