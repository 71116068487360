/* REACT-SLICK MARGIN SPACE STYLE */
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
/* REACT-SLICK NAVIGATION STYLE */
.slick-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-dots li {
  width: 7px !important;
  height: 7px !important;
  border-radius: 50%;
  background-color: gray;
}
.slick-dots li.slick-active {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #1d48a6;
}
