figure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.my-container strong {
  color: rgb(29 72 166);
}
